import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';

function App() {
  return (
  <>
    <Navbar />
    <Home />
    <Footer />
  </>    
  );
}

export default App;
