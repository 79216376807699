import React from "react";

const Home = () => {
    return (
        <div className="Hero">
            <div className="card bg-dark text-white border-0">
                <img src="/assets/bg.jpg" className="card-img" alt="65 R - 1" height="300px" />
            </div>
        </div>
        
    )
}

export default Home