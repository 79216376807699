import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const Footer = () => {
  return (
    <MDBFooter color="white" className="font-small pt-4 mt-4 text-black">
      
      <div className="text-center text-black py-3">
        <MDBContainer>
          <a className="text-black" href="mailto:info@baggermobile.com"> info@baggermobile.com </a>
        </MDBContainer>
      </div>
      <div className="text-center text-black py-3">
        <MDBContainer>
          &copy; {new Date().getFullYear()} Copyright: <a className="text-black" 
          href="/"> baggermobile.com </a>
        </MDBContainer>
      </div>
    </MDBFooter>
    
  );
}

export default Footer;